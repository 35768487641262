<template>
  <div id="userLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <div class="title-bar">
          <img
            src="../assets/ojnbc-logo.svg"
            class="logo"
            @click="handleLogoClick"
          />
        </div>
      </a-layout-header>
      <a-layout-content class="content">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">
        <a href=" " target="_blank" style="text-decoration: none; color: red"
          >Northern British Columbia Coding Practice Platform</a
        >
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style scoped>
#userLayout .logo {
  cursor: pointer; /* 使鼠标悬浮时显示手型光标 */
}

#userLayout {
  text-align: center;
}

#userLayout .logo {
  height: 540px;
  display: block;
  align-items: center;
}

.title-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  overflow: hidden;
}

#userLayout .header {
  margin-top: 16px;
}

#userLayout .content {
  margin-bottom: 16px;
  padding: 20px;
}

#userLayout .footer {
  padding: 16px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
</style>

<script>
export default {
  methods: {
    handleLogoClick() {
      this.$router.push("/questions");
    },
  },
};
</script>
