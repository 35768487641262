/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_VerCode_ } from '../models/BaseResponse_VerCode_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CaptureControllerService {
    /**
     * captcha
     * @returns BaseResponse_VerCode_ OK
     * @throws ApiError
     */
    public static captchaUsingGet(): CancelablePromise<BaseResponse_VerCode_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * captcha
     * @returns BaseResponse_VerCode_ OK
     * @throws ApiError
     */
    public static captchaUsingHead(): CancelablePromise<BaseResponse_VerCode_> {
        return __request(OpenAPI, {
            method: 'HEAD',
            url: '/api/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * captcha
     * @returns BaseResponse_VerCode_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static captchaUsingPost(): CancelablePromise<BaseResponse_VerCode_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * captcha
     * @returns BaseResponse_VerCode_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static captchaUsingPut(): CancelablePromise<BaseResponse_VerCode_ | any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * captcha
     * @returns BaseResponse_VerCode_ OK
     * @throws ApiError
     */
    public static captchaUsingDelete(): CancelablePromise<BaseResponse_VerCode_> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * captcha
     * @returns BaseResponse_VerCode_ OK
     * @throws ApiError
     */
    public static captchaUsingOptions(): CancelablePromise<BaseResponse_VerCode_> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/api/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * captcha
     * @returns BaseResponse_VerCode_ OK
     * @throws ApiError
     */
    public static captchaUsingPatch(): CancelablePromise<BaseResponse_VerCode_> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
