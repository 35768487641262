<template>
  <div id="addQuestionView" class="centered-container">
    <h2 style="text-align: center">Create Question</h2>
    <div style="margin-top: 16px"></div>
    <a-space style="width: auto">
      <a-form :model="form" label-align="left">
        <a-form-item field="title" label="title">
          <a-input v-model="form.title" placeholder="please enter title" />
        </a-form-item>
        <a-form-item field="tags" label="tags">
          <a-input-tag
            v-model="form.tags"
            placeholder="Please Enter tags"
            allow-clear
          />
        </a-form-item>
        <a-form-item
          field="content"
          label="content"
          :style="editorStyles.content"
        >
          <MdEditor
            :value="form.content"
            :handleChange="onContentChange"
            @click="updateZIndex('content')"
          />
        </a-form-item>
        <a-form-item field="answer" label="answer" :style="editorStyles.answer">
          <MdEditor
            :value="form.answer"
            :handleChange="onAnswerChange"
            @click="updateZIndex('answer')"
          />
        </a-form-item>
        <div style="margin-top: 16px"></div>
        <a-form-item
          label="computing configuration"
          :content-flex="false"
          :merge-props="false"
        >
          <a-space direction="vertical" style="min-width: 580px">
            <a-form-item field="judgeConfig.timeLimit" label="time limit">
              <a-input-number
                v-model="form.judgeConfig.timeLimit"
                placeholder="please enter time limit"
                mode="button"
                size="large"
              />
            </a-form-item>
            <a-form-item field="judgeConfig.memoryLimit" label="memory limit">
              <a-input-number
                v-model="form.judgeConfig.memoryLimit"
                placeholder="please enter memory limit"
                mode="button"
                size="large"
              />
            </a-form-item>
            <a-form-item field="judgeConfig.stackLimit" label="stack limit">
              <a-input-number
                v-model="form.judgeConfig.stackLimit"
                placeholder="please enter stack Limit"
                mode="button"
                size="large"
              />
            </a-form-item>
          </a-space>
        </a-form-item>
        <a-form-item
          label="test case config"
          :content-flex="false"
          :merge-props="false"
        >
          <a-form-item
            v-for="(judgeCaseItem, index) of form.judgeCase"
            :key="index"
            no-style
          >
            <a-space direction="vertical" style="min-width: 580px">
              <a-form-item
                :field="`form.judgeCase[${index}].input`"
                :label="`input case - ${index + 1}`"
                :key="index"
              >
                <a-input
                  v-model="judgeCaseItem.input"
                  placeholder="please enter test input case"
                />
              </a-form-item>
              <a-form-item
                :field="`form.judgeCase[${index}].output`"
                :label="`output case - ${index + 1}`"
                :key="index"
              >
                <a-input
                  v-model="judgeCaseItem.output"
                  placeholder="please enter test output case"
                />
              </a-form-item>
              <a-button @click="handleDelete(index)">Delete</a-button>
            </a-space>
          </a-form-item>
          <div style="margin-top: 32px">
            <a-button @click="handleAdd">addNewTestCase</a-button>
          </div>
        </a-form-item>
        <div style="margin-top: 16px"></div>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            style="background-color: red; border-color: red; min-width: 600px"
            @click="doSubmit"
            >Submit
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import MdEditor from "@/components/MdEditor.vue";
import { QuestionControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRoute } from "vue-router";

const route = useRoute();
const updagePage = route.path.includes("update");

let form = ref({
  title: "",
  tags: [],
  answer: "",
  content: "",
  judgeConfig: {
    memoryLimit: 1000,
    stackLimit: 1000,
    timeLimit: 1000,
  },
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
});

const loadDate = async () => {
  const id = route.query.id;
  if (!id) {
    return;
  }
  const res = await QuestionControllerService.getQuestionByIdUsingGet(
    id as any
  );
  console.log(res);
  if (res.code === 0) {
    form.value = res.data as any;
    if (!form.value.judgeCase) {
      form.value.judgeCase = [
        {
          input: "",
          output: "",
        },
      ];
    } else {
      form.value.judgeCase = JSON.parse(form.value.judgeCase as any);
    }
    if (!form.value.judgeConfig) {
      form.value.judgeConfig = {
        memoryLimit: 1000,
        stackLimit: 1000,
        timeLimit: 1000,
      };
    } else {
      form.value.judgeConfig = JSON.parse(form.value.judgeConfig as any);
    }
    if (!form.value.tags) {
      form.value.tags = [];
    } else {
      form.value.tags = JSON.parse(form.value.tags as any);
    }
  } else {
    message.error("loading error" + res.message);
  }
};

onMounted(() => {
  loadDate();
});

const editorStyles = reactive({
  content: { zIndex: "auto" }, // 默认使 content 编辑器具有高 z-index
  answer: { zIndex: "auto" },
});

const updateZIndex = (active: "content" | "answer") => {
  editorStyles.content.zIndex = active === "content" ? "1000" : "auto";
  editorStyles.answer.zIndex = active === "answer" ? "1000" : "auto";
};

const doSubmit = async () => {
  console.log(form.value);
  if (updagePage) {
    const res = await QuestionControllerService.updateQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("update success");
    } else {
      message.error("update failed" + res.message);
    }
  } else {
    const res = await QuestionControllerService.addQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("create success");
    } else {
      message.error("create failed" + res.message);
    }
  }
};

const handleAdd = () => {
  form.value.judgeCase.push({
    input: "",
    output: "",
  });
};

const handleDelete = (index: number) => {
  form.value.judgeCase.splice(index, 1);
};

const onContentChange = (v: string) => {
  form.value.content = v;
};

const onAnswerChange = (v: string) => {
  form.value.answer = v;
};
</script>
<style scoped>
.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 如果需要，可以加这行来垂直居中整个页面的内容 */
  padding: 20px; /* 提供一些内边距 */
}
</style>
