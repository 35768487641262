<template>
  <div id="questionsView">
    <div id="searchForm">
      <div id="sf">
        <a-form :model="searchParams" layout="inline">
          <a-form-item field="title" label="Title">
            <a-input
              v-model="searchParams.title"
              placeholder="please enter question title"
            />
          </a-form-item>
          <a-form-item field="tags" label="Tags">
            <a-input-tag
              v-model="searchParams.tags"
              placeholder="please enter question tags"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              class="Search"
              type="primary"
              style="background-color: red; border-color: red"
              @click="doSubmit"
              >Search
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div style="margin-top: 0px"></div>
    <a-divider size="0" />
    <a-table
      v-show="isTableVisible"
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
    >
      <template #id="{ record }">
        <a-space>
          <div
            :style="
              questionACList.includes(idMap[record.id].toString())
                ? { color: 'limegreen', fontWeight: 'bold' }
                : {}
            "
          >
            {{ record.id }}
          </div>
        </a-space>
      </template>
      <template #title="{ record }">
        <a-space>
          <a-link
            class="custom-link"
            :href="'/view/question/' + idMap[record.id] + '/' + record.id"
            target="_blank"
          >
            {{ record.title }}
          </a-link>
        </a-space>
      </template>
      <template #tags="{ record }">
        <a-space>
          <a-tag v-for="(tag, index) of record.tags" :key="index" color="red"
            >{{ tag }}
          </a-tag>
        </a-space>
      </template>
      <template #acceptedRate="{ record }">
        {{
          `${
            record.submitNum
              ? ((record.acceptedNum / record.submitNum) * 100).toFixed(1)
              : "0.0"
          }%(${record.acceptedNum}/${record.submitNum})`
        }}
      </template>
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
    </a-table>
  </div>
</template>
<script setup lang="ts">
import { h, onMounted, ref, watchEffect, watch, computed } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";

const store = useStore();
const questionACList = computed(
  () => store.state.user?.loginUser?.questionACList ?? "no ac list"
);
const idMap = ref({});
const total = ref(0);
const dataList = ref([]);
const startIndex = ref(0);

const searchParams = ref<QuestionQueryRequest>({
  title: "",
  tags: [],
  pageSize: 23,
  current: 1,
});

watch(
  () => searchParams.value.current,
  (newVal) => {
    startIndex.value =
      ((newVal as number) - 1) * (searchParams.value.pageSize as number);
  }
);

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionVoByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    const recordsWithResetId = res.data.records.map((item, index) => {
      const newId = startIndex.value + index + 1;
      idMap.value[newId] = item.id; // 建立映射
      return {
        ...item,
        id: newId, // 显示用的新id
      };
    });
    dataList.value = recordsWithResetId;
    total.value = res.data.total;
  } else {
    message.error("loading info error : " + res.message);
  }
};

watchEffect(() => {
  loadData();
});

const isTableVisible = ref(false);

onMounted(() => {
  loadData().then(() => {
    isTableVisible.value = true; // 设置表格为可见状态
  });
});

const columns = [
  {
    title: "id",
    slotName: "id",
    align: "center",
  },
  {
    title: "title",
    align: "left",
    slotName: "title",
  },
  {
    title: "tags",
    align: "left",
    slotName: "tags",
  },
  {
    title: "AC Rate",
    align: "center",
    slotName: "acceptedRate",
  },
  /*  {
      title: "createTime",
      align: "center",
      slotName: "createTime",
    },*/
  {
    title: "submitNum",
    align: "center",
    dataIndex: "submitNum",
  },
];

const doSubmit = () => {
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const router = useRouter();

/*const toQuestionPage = (question: Question) => {
  const originalId = idMap.value[question.id]; // 从映射中获取原始id
  const url = `/view/question/${originalId}/${question.id}`;
  window.open(url, "_blank");

  /!*  router.push({
      path: `/view/question/${originalId}/${question.id}`,
    });*!/
};*/
</script>

<style scoped>
#searchForm {
  width: 100%;
  display: flex;
  justify-content: center;
}

::v-deep .arco-pagination-item-active,
.arco-pagination-item-active:hover {
  color: red;
  background-color: var(--color-danger-light-2);
  border-color: transparent;
  transition: color 0.2s cubic-bezier(0, 0, 1, 1),
    background-color 0.2s cubic-bezier(0, 0, 1, 1);
}

::v-deep .arco-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-style: normal;
  vertical-align: -2px;
  outline: none;
  stroke: currentColor;
  color: initial; /* 设置默认颜色，可以是black或其他颜色 */
}

::v-deep .arco-icon:hover {
  color: red; /* 鼠标悬停时颜色变为红色 */
}

.custom-link {
  color: black; /* 默认颜色为黑色 */
}

.custom-link:hover {
  color: red; /* 鼠标悬浮时颜色变为红色 */
  background-color: var(--color-danger-light-1);
}

#questionsView {
  white-space: nowrap; /* 防止内容换行 */
  max-width: 1440px;
  margin: 0 auto;
}
</style>
