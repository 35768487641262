/* eslint-disable */
<template>
  <div id="viewQuestionView"></div>
  <a-row :gutter="[24, 24]">
    <a-col :md="12" :xs="24">
      <a-tabs v-model:activeKey="activeTabKey" @tabClick="handleTabClick">
        <a-tab-pane key="question" title="Question">
          <a-card
            id="leftCard"
            v-if="question || ''"
            :title="`${props.orderId}.${question.title}`"
            style="
              height: 90vh;
              min-height: 568px;
              max-height: 1900px;
              overflow-y: auto;
            "
          >
            <MdViewer :value="question.content" />
            <template #extra>
              <a-space>
                <a-tag
                  v-for="(tag, index) of question.tags"
                  :key="index"
                  color="red"
                  >{{ tag }}
                </a-tag>
              </a-space>
            </template>
            <a-descriptions title="Judge Condition" :column="{ xs: 1, md: 2 }">
              <a-descriptions-item label="Time Limit">
                {{ question?.judgeConfig?.timeLimit ?? 0 }}
              </a-descriptions-item>
              <a-descriptions-item label="Memory Limit">
                {{ question?.judgeConfig?.memoryLimit ?? 0 }}
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="comment" title="Comment">
          <a-card style="border-top: none">
            <a-typography-paragraph>
              The comment feature is coming soon.
            </a-typography-paragraph>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="answer" title="Answer">
          <a-card style="border-top: none">
            <a-typography-paragraph>
              The answer feature is coming soon.
            </a-typography-paragraph>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="submission" title="Submission">
          <a-card style="border-top: none">
            <a-table
              :columns="columns"
              :data="dataList"
              :pagination="{
                showTotal: true,
                pageSize: searchParams.pageSize,
                current: searchParams.current,
                total,
              }"
              @page-change="onPageChange"
            >
              <template #createTime="{ record }">
                {{ moment(record.createTime).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
              <template #status="{ record }">
                <span
                  class="link-like"
                  @click="() => handleStatusClick(record)"
                  :style="{
                    color:
                      record.judgeInfo.message === 'Accepted' ? 'green' : 'red',
                  }"
                >
                  {{ record.judgeInfo.message }}
                </span></template
              >
              <template #runtime="{ record }">
                <a-space>
                  {{ (record.judgeInfo.time / 1000000).toFixed(0) + "ms" }}
                </a-space>
              </template>
              <template #memory="{ record }">
                <a-space
                  >{{ (record.judgeInfo.memory / 1048576).toFixed(0) + "mb" }}
                </a-space>
              </template>
            </a-table>
          </a-card>
        </a-tab-pane>
        <a-tab-pane
          v-if="showDetailsTab"
          :key="'details'"
          :title="
            selectedRecord.judgeInfo.message === 'Accepted'
              ? selectedRecord.judgeInfo.message + ' \u2714' + ' \uD83D\uDE00'
              : selectedRecord.judgeInfo.message + ' \uD83D\uDD14'
          "
        >
          <a-card :title="title" style="border-top: none">
            <a-card
              v-if="selectedRecord.judgeInfo.message === 'Accepted'"
              style="background-color: #e8f5e9; border-bottom: none"
            >
              <div style="color: lime; font-size: 36px; font-weight: bold">
                Accepted
              </div>
            </a-card>
            <a-card
              v-if="selectedRecord.judgeInfo.outputInfo"
              style="background-color: #ffebee; border-bottom: none"
            >
              <div style="color: red">
                Output: {{ selectedRecord.judgeInfo.outputInfo }}
              </div>
            </a-card>
            <a-card
              v-if="
                selectedRecord.judgeInfo.message != 'Accepted' &&
                selectedRecord.judgeInfo.message != 'Compile Error'
              "
              style="background-color: #ffebee; border-bottom: none"
            >
              <div style="color: #ff0000; font-size: 36px; font-weight: bold">
                {{ selectedRecord.judgeInfo.message }}
              </div>
            </a-card>
            <a-card>
              <div>
                Runtime:
                {{
                  (selectedRecord.judgeInfo.time / 1000000).toFixed(0) + "ms"
                }}
              </div>
              <div>
                Memory:
                {{
                  (selectedRecord.judgeInfo.memory / 1048576).toFixed(0) + "mb"
                }}
              </div>
              <div>
                Language:
                {{ selectedRecord.language }}
              </div>
              <div>
                Timestamp:
                {{
                  moment(selectedRecord.createTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </div>
            </a-card>
            <a-card style="border-top: none">
              <div>
                <highlightjs :language="language" :code="code" />
              </div>
            </a-card>
          </a-card>
        </a-tab-pane>
      </a-tabs>
    </a-col>
    <a-col :md="12" :xs="24">
      <a-form
        :model="form"
        layout="inline"
        style="display: flex; align-items: flex-end"
      >
        <a-form-item field="title" label="Language" style="min-width: 120px">
          <a-select
            v-model="form.language"
            :style="{ width: '120px', color: 'red' }"
            placeholder="Select Language"
          >
            <a-option>python</a-option>
            <a-option>java</a-option>
            <a-option>cpp</a-option>
            <a-option>javascript</a-option>
            <a-option>go</a-option>
          </a-select>
        </a-form-item>
      </a-form>
      <a-card
        style="
          min-height: 568px;
          height: 90vh;
          max-height: 1900px;
          font-weight: bold;
        "
      >
        <template #title>
          <div class="code-title-and-button">
            <span> <span style="color: red">&lt;/&gt;</span>Code </span>
            <div class="button-container">
              <a-button
                type="primary"
                v-if="isTurnstileReady"
                style="
                  background-color: red;
                  border-color: red;
                  min-width: 100px;
                "
                @click="doSubmit"
                >Submit
              </a-button>
              <a-spin dot v-if="isLoading" style="color: red" />
            </div>
          </div>
        </template>
        <CodeEditor
          :value="form.code as string"
          :language="form.language"
          :handleChange="changeCode"
        />
      </a-card>
      <a-divider size="0" />
    </a-col>
  </a-row>
  <div id="myWidget" style="position: absolute; top: 67px; right: 20px"></div>
</template>
<script setup lang="ts">
import { onMounted, ref, watchEffect, withDefaults, defineProps } from "vue";
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionSubmitControllerService,
  QuestionSubmitQueryRequest,
  QuestionVO,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import CodeEditor from "@/components/CodeEditor.vue";
import MdViewer from "@/components/MdViewer.vue";
import moment from "moment/moment";
import { useStore } from "vuex";

const store = useStore();
const activeTabKey = ref("question");
const showDetailsTab = ref(false);
const selectedRecord = ref(null);
const title = ref();
const isLoading = ref(true);
const isDataLoaded = ref(false);
const isTurnstileReady = ref(false);
let code = "";
let language = "";

const handleTabClick = (key, event) => {
  if (key === "submission" && !isDataLoaded.value) {
    loadSubmissionData();
    isDataLoaded.value = true;
  }
};

const handleStatusClick = (record) => {
  selectedRecord.value = record;
  showDetailsTab.value = true; // 显示新的标签页
  activeTabKey.value = "details";
  code = record.code;
  language = record.language;
};

interface Props {
  id: string;
  orderId: string;
}

const columns = [
  {
    title: "Timestamp",
    align: "center",
    slotName: "createTime",
  },
  {
    title: "Status",
    align: "center",
    slotName: "status",
  },
  {
    title: "Runtime",
    align: "center",
    slotName: "runtime",
  },
  {
    title: "Memory",
    align: "center",
    slotName: "memory",
  },
  {
    title: "Language",
    align: "center",
    dataIndex: "language",
  },
];

const total = ref(0);
const dataList = ref([]);

function loadTurnstileScript() {
  const widgetContainer = document.querySelector("#myWidget");
  if (widgetContainer) {
    widgetContainer.innerHTML = ""; // 清空容器中的所有内容
  }

  const script = document.createElement("script");
  script.src =
    "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=_turnstileCb";
  script.defer = true;
  document.head.appendChild(script);

  window._turnstileCb = () => {
    console.debug("_turnstileCb called");
    if (window.turnstile) {
      window.turnstile.render("#myWidget", {
        sitekey: "0x4AAAAAAAi5rDgrzlId28SF",
        theme: "light",
        callback: function (token) {
          form.value.cf_turnstile_response = token;
          isTurnstileReady.value = true;
          isLoading.value = false;
        },
      });
    }
  };
}

const loadSubmissionData = async () => {
  const res =
    await QuestionSubmitControllerService.listQuestionSubmitByPageUsingPost(
      searchParams.value
    );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("loading info error : " + res.message);
  }
};

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
  loadSubmissionData();
};

const props = withDefaults(defineProps<Props>(), {
  id: () => "",
  orderId: () => "",
});

const question = ref<QuestionVO>();
const searchParams = ref<QuestionSubmitQueryRequest>({
  questionId: props.id,
  pageSize: 15,
  current: 1,
});

const loadData = async () => {
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
    props.id as any
  );
  if (res.code === 0) {
    question.value = res.data;
    document.title = question.value.title;
  } else {
    message.error("loading info error : " + res.message);
  }
};

const form = ref<QuestionSubmitAddRequest>({
  language: "java",
  code: "",
  cf_turnstile_response: "",
});

const doSubmit = async () => {
  isTurnstileReady.value = false;
  isLoading.value = true;
  if (!question.value?.id) {
    return;
  }
  showDetailsTab.value = false;
  const res = await QuestionSubmitControllerService.doQuestionSubmitUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  loadTurnstileScript();
  if (res.code == 0) {
    if (res.data.judgeInfo.message === "Accepted") {
      //
      store.commit("user/addACQuestionId", question.value.id);
    }
    total.value = parseInt(total.value, 10) + 1;
    handleStatusClick(res.data);
    dataList.value.unshift(res.data);
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  } else {
    message.error("Submit failed: " + res.message);
  }
};

const changeCode = (v: string) => {
  form.value.code = v;
};

watchEffect(() => {
  //loadSubmissionData();
});

onMounted(() => {
  loadData();
  loadTurnstileScript();
});
</script>

<style scoped>
.code {
  background-color: #f4f4f4; /* 设置背景颜色 */
  border: 1px solid #ccc; /* 设置边框 */
  border-radius: 5px; /* 边框圆角 */
  padding: 10px; /* 内边距 */
  font-family: "Courier New", monospace; /* 设置字体 */
  color: #333; /* 文本颜色 */
  white-space: pre-wrap; /* 保持空格和换行 */
}

.link-like {
  cursor: pointer; /* 将鼠标指针变为手形，模拟超链接 */
}

.link-like:hover {
  text-decoration: underline; /* 鼠标悬浮时添加下划线 */
}

#searchForm {
  width: 100%;
  display: flex;
  justify-content: center;
}

.code-title-and-button {
  display: flex;
  justify-content: space-between; /* 设置内容左右分布 */
  align-items: center; /* 垂直居中对齐 */
  width: 100%; /* 确保 div 撑满卡片宽度 */
}

.button-container {
  margin-left: auto; /* 自动左边距推动按钮到右边 */
}

::v-deep .arco-tabs-content {
  box-sizing: border-box;
  width: 100%;
  padding-top: 0px;
  overflow: hidden;
}

#leftCard {
  height: 100%;
  border: 1px solid var(--color-neutral-3);
  border-top: none; /* 移除顶部边框 */
  border-radius: var(--border-radius-small);
}

.button-container {
  display: flex;
  justify-content: flex-end; /* 使内容靠右对齐 */
}

::v-deep .arco-pagination-item-active,
.arco-pagination-item-active:hover {
  color: red;
  background-color: var(--color-danger-light-2);
  border-color: transparent;
  transition: color 0.2s cubic-bezier(0, 0, 1, 1),
    background-color 0.2s cubic-bezier(0, 0, 1, 1);
}

::v-deep .arco-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-style: normal;
  vertical-align: -2px;
  outline: none;
  stroke: currentColor;
  color: initial; /* 设置默认颜色，可以是black或其他颜色 */
}

::v-deep .arco-icon:hover {
  color: red; /* 鼠标悬停时颜色变为红色 */
}

.custom-link {
  color: black; /* 默认颜色为黑色 */
}

.custom-link:hover {
  color: red; /* 鼠标悬浮时颜色变为红色 */
  background-color: var(--color-danger-light-1);
}

#viewQuestionView {
  max-width: 1440px;
  margin: 0 auto;
}

::v-deep .arco-tabs-tab-active,
.arco-tabs-tab-active:hover {
  color: red;
  font-weight: 500;
}

::v-deep .arco-tabs-nav-ink {
  position: absolute;
  top: initial;
  right: initial;
  bottom: 0;
  height: 2px;
  background-color: red;
  transition: left 0.2s cubic-bezier(0.34, 0.69, 0.1, 1),
    width 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}

::v-deep .arco-tabs-tab-active,
.arco-tabs-tab-active:hover {
  color: red;
  font-weight: 500;
}

::v-deep .arco-tabs-nav-ink {
  position: absolute;
  top: initial;
  right: initial;
  bottom: 0;
  height: 2px;
  background-color: red;
  transition: left 0.2s cubic-bezier(0.34, 0.69, 0.1, 1),
    width 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}

::v-deep .arco-tabs-tab-active,
.arco-tabs-tab-active:hover {
  color: red !important;
  font-weight: 500;
}

.a-tab-pane .arco-tabs-tab-active,
.arco-tabs-tab-active:hover {
  color: red !important;
  font-weight: 500;
}

::v-deep .custom-tab-style .arco-tabs-tab-active,
::v-deep .custom-tab-style .arco-tabs-tab-active:hover {
  color: #00ff1c !important;
}
</style>
