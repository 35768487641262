<template>
  <a-row id="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="title-bar">
            <img
              class="logo"
              src="../assets/ojnbc-logo.svg"
              @click="handleLogoClick"
            />
          </div>
        </a-menu-item>
        <a-menu-item v-for="item in visibleRoutes" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="100px">
      <div class="clickable">
        <!-- 检查用户是否登录，如果是，显示用户名和注销链接 -->
        <template v-if="store.state.user?.loginUser?.userName">
          {{ store.state.user.loginUser.userName }} |
          <span class="logout-link" @click="handleLogOutClick">Logout</span>
        </template>
        <!-- 如果用户未登录，显示登录链接 -->
        <span v-else class="login-link" @click="handleLoginClick">Login</span>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "../router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import { UserControllerService } from "../../generated";
import message from "@arco-design/web-vue/es/message";

const router = useRouter();
const store = useStore();

const handleLogoClick = () => {
  router.push("/questions");
};

const handleLoginClick = () => {
  if (!store.state.user?.loginUser?.userName) {
    router.push("/user/login?redirect=/questions");
  }
};

const visibleRoutes = computed(() => {
  return routes.filter((item, index) => {
    if (item.meta?.hideInMenu) {
      return false;
    }

    if (
      !checkAccess(store.state.user?.loginUser, item?.meta?.access as string)
    ) {
      return false;
    }
    return true;
  });
});

const selectedKeys = ref(["/"]);

router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

/*
setTimeout(() => {
  store.dispatch("user/getLoginUser", {
    userName: "Mark Zhang",
    userRole: ACCESS_ENUM.ADMIN,
  });
}, 5000);
*/

const logout = async () => {
  try {
    const res = await UserControllerService.userLogoutUsingPost(); // 根据实际URL调整
    return res;
  } catch (error) {
    console.error("Failed to logout ", error);
  }
};

const handleLogOutClick = () => {
  if (store.state.user?.loginUser?.userName) {
    if (window.confirm("Are you sure you want to logout?")) {
      logout()
        .then((response) => {
          if (response) {
            store.commit("user/logout"); // 假设你的 Vuex store 有一个 logout mutation
            message.success("Logged out successfully");
            router.push("/questions");
          }
        })
        .catch((error) => {
          message.error("Logout failed: " + error.message);
        });
    }
  } else {
    router.push("/user/login?redirect=/questions");
  }
};

const doMenuClick = (key: string) => {
  router.push({ path: key });
};

// ------------------ music ------------------

// ------------------ music ------------------
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.arco-menu-light .arco-menu-item.arco-menu-selected,
.arco-menu-light .arco-menu-group-title.arco-menu-selected,
.arco-menu-light .arco-menu-pop-header.arco-menu-selected,
.arco-menu-light .arco-menu-inline-header.arco-menu-selected {
  color: red;
}

::v-deep .arco-menu-selected-label {
  position: absolute;
  right: 12px;
  bottom: -14px;
  left: 12px;
  height: 3px;
  background-color: red;
  animation: arco-menu-selected-item-label-enter 0.2s cubic-bezier(0, 0, 1, 1);
}

.title-bar {
  display: flex;
  align-items: center;
  height: 52px;
  overflow: hidden;
}

.logo {
  cursor: pointer; /* 使鼠标悬浮时显示手型光标 */
  height: 520px;
}

.clickable {
  white-space: nowrap;
}

@media (max-width: 665px) {
  .title-bar {
    display: none !important;
  }
}

.logout-link,
.login-link {
  margin-right: 45px;
  cursor: pointer; /* 设置鼠标悬停时的光标为点击手型 */
  white-space: nowrap; /* 防止内容换行 */
}

.logout-link:hover,
.login-link:hover {
  color: red; /* 鼠标悬停时字体颜色变为红色 */
  text-decoration: underline; /* 鼠标悬停时下划线 */
}

/* 其他样式保持不变 */
</style>
