<template>
  <div id="app" ref="vueDom" style="display: none">
    <a-config-provider :locale="enUS">
      <template v-if="route.path === '/'">
        <HomeView />
      </template>
      <template v-else-if="route.path.startsWith('/user')">
        <router-view />
      </template>
      <template v-else>
        <BasicLayout />
      </template>
    </a-config-provider>
  </div>
</template>

<style>
#app {
}
</style>

<script setup lang="ts">
import BasicLayout from "@/layouts/BasicLayout.vue";
import HomeView from "@/views/question/HomeView.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import enUS from "@arco-design/web-vue/es/locale/lang/en-us";

const vueDom = ref(null);
const route = useRoute();
const doInit = () => {
  console.log("hello 欢迎来的我的项目");
  setTimeout(() => {
    if (vueDom.value) {
      vueDom.value.style.display = "block";
    }
  }, 300);
};

onMounted(() => {
  doInit();
});
</script>
