<template>
  <div
    id="code-editor"
    ref="codeEditorRef"
    style="height: 81vh; min-height: 500px; max-height: 1800px"
  />
  <!--  <a-button @click="fillValue">Fill Value</a-button>-->
</template>

<script setup lang="ts">
import * as monaco from "monaco-editor";
import {
  onMounted,
  ref,
  toRaw,
  withDefaults,
  defineProps,
  watch,
  reactive,
} from "vue";

/**
 * 定义组件属性的类型
 */
interface Props {
  value: string;
  language?: string;
  handleChange: (v: string) => void;
}

const props = withDefaults(defineProps<Props>(), {
  value: () => "",
  language: () => "java",
  handleChange: (v: string) => {
    console.log(v);
  },
});

const codeEditorRef = ref();
const codeEditor = ref();
const codeStorage = new Map(); // 使用 reactive 包裹 Map 以保持响应性

watch(
  () => props.language,
  (newLang, oldLang) => {
    if (newLang !== oldLang) {
      if (
        !codeStorage.get(oldLang) &&
        toRaw(codeEditor.value).getValue() === getDefaultComment(oldLang)
      ) {
        codeStorage.set(oldLang, getDefaultComment(oldLang));
      } else {
        codeStorage.set(oldLang, toRaw(codeEditor.value).getValue());
      }

      if (!codeStorage.get(newLang)) {
        toRaw(codeEditor.value).setValue(getDefaultComment(newLang));
      } else {
        toRaw(codeEditor.value).setValue(codeStorage.get(newLang));
      }

      monaco.editor.setModelLanguage(
        toRaw(codeEditor.value).getModel(),
        newLang
      );
    }
  }
);

function getDefaultComment(language: string) {
  switch (language) {
    case "python":
      return (
        "'''\n" +
        " Please define the variables for receiving data \n" +
        " according to the content of the problem.       \n" +
        " The method of data input should follow the ACM \n" +
        " rules.    ## (version: python 3.x) ##          \n" +
        "'''\n"
      );
    default:
      return (
        "// Please define the variables for receiving data\n" +
        "// according to the content of the problem.\n" +
        "// The method of data input should follow the ACM\n" +
        "// rules.\n"
      );
  }
}

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }

  monaco.editor.defineTheme("customMonokaiLightEnhanced", {
    base: "vs", // 基于亮色主题
    inherit: true, // 继承基础主题的设置
    rules: [
      { token: "keyword", foreground: "cc3a4b" }, // 关键字为深粉红色
      { token: "comment", foreground: "7a7a7a" }, // 注释为较深灰色
      { token: "string", foreground: "5ca632" }, // 字符串为深绿色
      { token: "number", foreground: "c47f2c" }, // 数字为较深橙色
      { token: "type.identifier", foreground: "c75233" }, // 类型和标识符使用深橙色
      { token: "delimiter", foreground: "c75233" }, // 分隔符也使用深橙色
    ],
    colors: {
      "editor.background": "#FFFFFF", // 背景色为白色
      "editor.foreground": "#000000", // 文本颜色为黑色
      "editorCursor.foreground": "#000000", // 光标颜色为黑色
      "editor.lineHighlightBackground": "#f0f0f0", // 当前行高亮为非常浅的灰色
      "editorLineNumber.foreground": "#636363", // 行号颜色为深灰色
      "editor.selectionBackground": "#d0d0d0", // 选择文本的背景颜色为中等灰色
      "editor.inactiveSelectionBackground": "#e0e0e0", // 非活动选择的背景色为浅灰色
    },
  });

  codeEditor.value = monaco.editor.create(codeEditorRef.value, {
    value: props.value || getDefaultComment(props.language),
    language: props.language,
    colorDecorators: true,
    automaticLayout: true,
    minimap: {
      enabled: false,
    },
    readOnly: false,
    theme: "customMonokaiLightEnhanced",
    fontSize: 17,
  });

  codeEditor.value.onDidChangeModelContent(() => {
    props.handleChange(toRaw(codeEditor.value).getValue());
  });
});

// Hover on each property to see its docs!
</script>

<style scoped></style>
