<template>
  <div
    id="userLoginView"
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
    "
  >
    <a-card>
      <a-form
        style="max-width: 580px; margin: 0 auto"
        label-align="left"
        auto-label-width
        :model="form"
        @submit="handleSubmit"
      >
        <a-form-item
          field="userAccount"
          tooltip="Please enter username"
          label="Username"
        >
          <a-input
            v-model="form.userAccount"
            placeholder="please enter your username"
            style="min-width: 280px"
          />
        </a-form-item>
        <a-form-item field="post" label="Password">
          <a-input-password
            v-model="form.userPassword"
            placeholder="please enter your password"
            style="min-width: 280px"
          />
        </a-form-item>
        <a-form-item style="display: flex; justify-content: space-between">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <a-input
              v-model="form.verCode"
              placeholder="Verification Code"
            ></a-input>
            <div style="display: flex; height: 36px">
              <img v-bind:src="captchaUrl" @click="clickImg" width="140px" />
            </div>
          </div>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            v-if="isTurnstileReady"
            style="width: 100%; background-color: red; border-color: red"
            >Login
          </a-button>
          <a-spin dot v-if="isLoading" style="color: red; width: 100%" />
        </a-form-item>
        <a-form-item style="text-align: center">
          <div style="flex: 1; justify-content: center">
            Not registered yet? please
            <span style="color: red; cursor: pointer" @click="toRegister"
              >register</span
            >
          </div>
        </a-form-item>
      </a-form>
      <div class="checkbox mb-3">
        <!-- The Turnstile widget will be injected in the following div. -->
        <div id="myWidget"></div>
        <!-- end. -->
      </div>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, onMounted } from "vue";
import {
  CaptureControllerService,
  UserControllerService,
  UserLoginRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

//data:image/png;base64,
const form = reactive({
  userAccount: "",
  userPassword: "",
  verCode: "",
  key: "",
  cf_turnstile_response: "",
} as UserLoginRequest);

const isTurnstileReady = ref(false);
const isLoading = ref(true);

const router = useRouter();
const store = useStore();
const route = useRoute(); // 获取当前路由对象
const captchaUrl = ref();

const toRegister = () => {
  router.push("/user/register");
};

const fetchCaptcha = async () => {
  try {
    // 向后端发送请求获取验证码
    const res = await CaptureControllerService.captchaUsingGet(); // 根据实际URL调整
    if (res.code == 0) {
      form.verCode = "";
      form.key = res.data?.key;
      captchaUrl.value = res.data?.base64Code;
    } else {
      message.error("Submit failed: " + res.message);
    }
  } catch (error) {
    console.error("Failed to fetch captcha: ", error);
  }
};

const clickImg = async () => {
  fetchCaptcha();
};

function loadTurnstileScript() {
  const widgetContainer = document.querySelector("#myWidget");
  if (widgetContainer) {
    widgetContainer.innerHTML = ""; // 清空容器中的所有内容
  }

  const script = document.createElement("script");
  script.src =
    "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=_turnstileCb";
  script.defer = true;
  document.head.appendChild(script);

  window._turnstileCb = () => {
    console.debug("_turnstileCb called");
    if (window.turnstile) {
      window.turnstile.render("#myWidget", {
        sitekey: "0x4AAAAAAAi5rDgrzlId28SF",
        theme: "light",
        callback: function (token) {
          form.cf_turnstile_response = token;
          isTurnstileReady.value = true;
          isLoading.value = false;
        },
      });
    }
  };
}

const handleSubmit = async () => {
  isTurnstileReady.value = false;
  isLoading.value = true;
  const res = await UserControllerService.userLoginUsingPost(form);
  if (res.code === 0) {
    await store.dispatch("user/getLoginUser");
    const redirect = route.query.redirect || "/questions";
    router.push({
      path: redirect,
      replace: true,
    });
  } else {
    fetchCaptcha();
    loadTurnstileScript();
    message.error("Login failed" + res.message);
  }
};

onMounted(() => {
  fetchCaptcha(); // 组件挂载后立即调用，加载验证码
  loadTurnstileScript();
});
</script>
