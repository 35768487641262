<template>
  <body>
    <section class="showcase">
      <header>
        <div class="logo">
          <img src="../../assets/homeLogo.svg" alt="" />
        </div>
      </header>

      <video
        v-if="!isMobile"
        src="../../assets/homeBackground.mp4"
        autoplay
        loop
        muted
      ></video>
      <div class="overlay"></div>

      <div class="text">
        <h2>Code Shapes Everything</h2>
        <h2>But Only You Can Make It Better the World</h2>
        <p>Join Fearless Northern BC to Forge a New Future</p>
        <router-link class="enter" to="/questions">Join Challenge!</router-link>
      </div>
      <div class="footer">
        <a
          href=" "
          target="_blank"
          style="text-decoration: none; color: red; font-weight: bold"
          >Northern British Columbia Coding Practice Platform</a
        >
      </div>
    </section>
  </body>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

const isMobile = ref(false);

// 检测是否为移动设备
function checkMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

onMounted(() => {
  isMobile.value = checkMobileDevice();
});
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.showcase {
  min-height: 100vh;
  color: white;
  background: black;
  display: flex;
  align-items: center;
  padding: 100px;
}

.showcase header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 40px 40px;
  z-index: 990;
}

.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: darkkhaki;
  mix-blend-mode: overlay;
}
*/
.logo {
  width: 600px;
}

.logo img {
  width: 110%;
}

.text {
  position: relative;
}

.text h2 {
  font-size: 5em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
}

.text p {
  font-size: 2em;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  margin: 20px 0;
  max-width: 1000px;
}

.text .enter {
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  background: red;
  padding: 10px 30px;
  text-decoration: none;
  color: white;
  margin-top: 10px;
  transition: 0.2s;
  font-weight: 400;
  z-index: 1001;
}

.text a:hover {
  font-weight: 400;
  letter-spacing: 6px;
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
}

@media (max-width: 991px) {
  .showcase,
  .showcase header {
    padding: 40px;
  }

  .text h2 {
    font-size: 3em;
  }

  .text p {
    font-size: 2em;
  }
}

.background {
  position: fixed; /* 固定位置，确保视频不随滚动条滚动 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw; /* 设置宽度为100%的视口宽度 */
  height: 100vh; /* 设置高度为100%的视口高度 */
  object-fit: cover; /* 保证视频内容填满整个元素区域，同时维持宽高比 */
  z-index: -1000; /* 确保视频在所有内容的后面 */
}
</style>
